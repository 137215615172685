/* This stylesheet generated by Transfonter (https://transfonter.org) on February 13, 2017 6:06 PM */

@font-face {
	font-family: 'Kabel';
	src: url('./fonts/KabelBook.eot');
	src: url('./fonts/KabelBook.eot?#iefix') format('embedded-opentype'),
		url('./fonts/KabelBook.woff2') format('woff2'),
		url('./fonts/KabelBook.woff') format('woff'),
		url('./fonts/KabelBook.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}
