@import 'css/kabelfont.css';

iframe {
  position: relative;
}

html {
  font-family: Kabel;
}

body {
  margin: 0;
  padding: 0;
  font-family: Kabel;
}

h3 {
  font-family: Kabel;
}

.container-page-width {
  width: 728px;
}

.main-section {
  margin-top: 88px;
}

.row-centered {
  text-align: center !important;
}

.kabel-font-main {
  font-family: Kabel;
  font-size: 24px;
}

.kabel-font-sub {
  font-family: Kabel;
  font-size: 18px;
  margin-top: -5px;
  text-align: center;
}

.selectedStyle {
  background-color: gray;
  color: #fff;
  cursor: pointer;
}

.unSelectedStyle {
  cursor: pointer;
}

.app-header {
  width: 100%;
  z-index: 1000;
  background-color: white;
  width: 728px;

}

.logo-wrapper {
  margin-left: 5px;
}

.title-logo {
  background-color: red;
  border-radius: 4px;
  color: white;
  padding: 3px;
  width: 150px; 
  text-align: center; 
}


.networth-total-box {
  height: 55px;
  width: 150px;
  background-color: #d6f1d6; 
}

.content-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.total-header {
  text-align: center;
  margin: 5px;
  color: gray;
  font-size: 10px;
}

.total-number {
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  color: gray;
  margin-top: -5px;
}

.sub-header {
  color: gray;
  /* font-weight: lighter; */
  text-align: center;
}

.dropdown-menu[aria-labelledby = ddown] {
  min-width: 40px;
  padding: 0px;
  box-shadow: 0 20px 40px rgba(0,0,0, 0.75);
}

.dropdown-menu[aria-labelledby = ddown] > li > a {
  padding: 9px 16px;
  text-align: left;
}


.btn-selections { /* These are the buttons inside the button list */
  width: 40px;
  height: 40px;
  margin: 1px;
  border-radius: 4px;
  color: white;
}

.btn-reset { /* These are the buttons inside the button list */
  width: 60px;
  height: 40px;
  margin: 10px;
  border-radius: 4px;
  color: white;
}

.ddown {
  width: 115px;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  color: gray;
  font-weight: lighter;
  padding-left: 5px;
  padding-right: auto;
}

/*Hide info of no checked inputs*/
.tabs__content input:not(:checked) ~ .tabs__content__info {
  display: none;
}



/* --- Navigation Tabs --- */

.tab-content {
  margin-top: 10px;
}

.nav-tabs {
  z-index: 1000;
  background-color: white;
}

.nav {
  display: flex;
  position: fixed;
  width: 728px;
}

.nav-tabs > li {
  font-family: Kabel;
  font-size: 12px;
}

.github-link {
  padding: 10px;
}

.property-tab:first-child:before {
  content: '';
  display: block;
  right: 100%;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid #aaa;
}

.property-tab:first-child:after {
  content: '';
  display: block;
  left: 100%;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid #aaa;
}

.cash-tab:first-child:before {
  content: '';
  display: block;
  right: 100%;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid #aaa;
}

.cash-tab:first-child:after {
  content: '';
  display: block;
  left: 100%;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid #aaa;
}

/*Google Ad Units*/

.ad-sense-container {
  top: 122px;
  /* height: 100px; */
  width: 728px;
}

.ad-sense-container-2 {
  top: 124px;
  height: 100px;
  width: 728px;
}

.ad-sense {
  display: block;
  /* position: fixed; */
  margin-top: 10px;
  height: 90px;
  width: 728px;
  z-index: 1001;
  background-color: lightgray;
}

.ad-sense-ins {
  display: block;
}

.ad-sense-desktop-ins {
  display: block;
}

.btc-orange {
  background-color: rgb(242, 146, 46);
}

.eth-blue {
  background-color: rgb(100, 130, 231);
}

.offscreen {
  position: absolute;
  left: -999em;
}

/* Mobile Views */

@media (max-width: 768px) {
  .main-section {
    margin-top: 125px;
  }
  .app-header {
    width: 100%;
  }
  .container-page-width {
    width: 100%;
  }

  .nav {
    width: 100%;
    top: 125px;
  }

  .ad-sense-container {
    width: 100%;
    top: 160px;
  }

  .ad-sense-ins {
    display: inline-block;
    width: 100%;
    height: 90px;
  }

  .ad-sense {
    display: inline-block;
    height: 90px;
    width: 100%;
    z-index: 1001;
    background-color: lightgray;
  }
}

@media (max-width: 500px) {
  .ad-sense-container {
    top: 173px;
  }
}

@media (max-width: 327px) {

  .ddown {
    width: 95px;
    font-size: 12px;
  }

  .networth-total-box {
    width: 100px;
  }

  .total-number {
    font-size: 18px;
    margin-top: 0px;
  }
}